<script>
import model from "@/mixins/model";
import formService from "@/services/form";

export default {
  name: "TaskFormsForm",

  mixins: [model],

  data() {
    return { forms: [] };
  },

  created() {
    this.getForms();
  },

  methods: {
    async getForms() {
      this.isLoading = true;
      try {
        const result = await formService.getForms({
          params: { isTaskCompatible: true },
        });
        this.forms = result;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="list")
      h3.sign__title Formulario
    article.modal__fields
      .modal__row
        fieldset.modal__field
          label.label * Modelo
          ValidationProvider(name="Modelo", rules="required", v-slot="{ errors }")
            el-select(v-model="model.form" placeholder="Seleccione formulario modelo")
              el-option(
                v-for="form in forms"
                :key="form._id"
                :label="form.name"
                :value="form._id"
              )
            span.has-error {{ errors[0] }}
</template>

<style lang="scss">
.forms__answers__title {
  margin-top: 20px;
}

.forms__answers {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light-gray;
}

.forms__answers__add {
  margin-bottom: 20px;
}
</style>
