import api from "./api";

const formService = {};
const endpoint = "/forms";

formService.getForms = function (params) {
  return api.get(`${endpoint}/type/form`, params).then((res) => res.data);
};

formService.postPatientForm = function (answer) {
  return api.post(`${endpoint}/type/patient`, answer).then((res) => res.data);
};

formService.getPatientFormsByPatient = function (patientId, params) {
  return api
    .get(`${endpoint}/type/patient/patient/${patientId}`, params)
    .then((res) => res.data);
};

export default formService;
